import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const API_URL = process.env.REACT_APP_API_URL;

const logIn = async (email, password) => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const body = `username=${email}&password=${password}`;
  try {
    const { data } = await axios.post(`${API_URL}/auth`, body, config);
    if (data.detail) {
      throw new Error(data.detail);
    }
    return data.access_token;
  } catch (e) {
    throw Error(e.message);
  }
};


/**
 * 
 * @param {{email}} param0 email to get password recover instructions
 * @returns promise
 */
const getVerificationCode = async ({email}) => {

 return axios.post(`${API_URL}/auth/forgot_password`, {email});

};

const resetPassword = async ({token,password}) => {

  return axios.post(`${API_URL}/auth/reset_password/${token}`, {password});
 
 };
 

const whoAmI = async (token) => {
  const config = {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const { data } = await axios(`${API_URL}/auth/me`, config);
    if (data.detail) {
      throw new Error(data.detail);
    }
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [byDefault, setByDefault] = useState(false);

  const authenticate = (user) => {
    if (user) {
      setAuthenticated(true);
      // se cambio permisos por roles mapaeados por nombres
      setIsAdmin(true);
    } else {
      setAuthenticated(false);
      setIsAdmin(false);
    }
  };

  const singIn = async (email, password) => {
    try {
      const accessToken = await logIn(email, password);
      const user = await whoAmI(accessToken);

      localStorage.setItem("tokenAsisc", accessToken);

      setByDefault(false);
      setToken(accessToken);
      setCurrentUser(user);
      authenticate(user);
      setError("");

      return user;
    } catch (e) {
      setError(e.message);
      throw error(e)
    }
  };

  const singOut = () => {
    localStorage.removeItem("tokenAsisc");
    setToken(null);
  };

  useEffect(() => {
    const gotToken = localStorage.getItem("tokenAsisc");
    if (gotToken) {
      setByDefault(false);
      setToken(gotToken);
    } else {
      setByDefault(true);
      setToken(null);
    }
  }, []);

  useEffect(() => {
    if (token) {
      const log = async (token) => {
        try {
          const user = await whoAmI(token);
          setCurrentUser(user);
        } catch {
          setCurrentUser(null);
        }
        setLoading(false);
      };
      log(token);
    } else {
      setCurrentUser(null);
    }
  }, [token]);

  useEffect(() => {
    authenticate(currentUser);
  }, [currentUser]);
  

  const value = {
    currentUser,
    token,
    isAdmin,
    authenticated,
    singIn,
    singOut,
    whoAmI,
    error,
    loading,
    byDefault,
    getVerificationCode,
    resetPassword,
    logIn
  };

  return (
    <AuthContext.Provider value={value}>{(!loading || byDefault) && children}</AuthContext.Provider>
  );
}
