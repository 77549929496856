import { useEffect, useState, useCallback } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Form as FormikForm, useFormikContext } from 'formik';
import { CustomField } from '../../../inputs/CustomField';
import Modal from '../../../modal/CustomModal';

import { useAxios } from "../../../../context/AxiosHookContext"

export const RegisterParticipantsForms = () => {
  const axiosHook = useAxios();
  const API_URL = process.env.REACT_APP_PADRON;
  const navigate = useNavigate();
  const { activityId } = useParams();

  if (!activityId) {
    navigate("/404")
  }

  const [activityName, setActivityName] = useState(null);
  const [ActivityLastDatePassed, setActivityLastDatePassed] = useState(false);
  const [activityLastDate, setActivityLastDate] = useState("");
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const { values, setFieldValue, isSubmitting,setValues } = useFormikContext();


  const getNameActivityDetails = useCallback(async () => {
    try {

      const { data } = await axiosHook.get(`activities/activities/public/${activityId}`);
      setActivityName(data.actividad);
      setActivityLastDate(data.lastEnrollDate)

    } catch (error) {
      navigate("/404");
    }
  }, [axiosHook, activityId,navigate]);

  useEffect(()=>{

    const handlePadron = async (participants) => {
      try{
        if (participants.length === 11 && !values.nombre && !values.apellido) {
          setLoadingDocuments(true);
  
          const response = await axiosHook.get(`activities/assistants/padron/${participants}`);
  
          const data = response?.data?.data || null;

          if (data){
            setValues({...values,...data})
          }
          
          
        } else {
          return;
        }
        setLoadingDocuments(false);
      } catch (e) {
        setLoadingDocuments(false);
      }
    };

    handlePadron(values?.documento || '')

  },[values,axios,API_URL])


  useEffect(() => {
    getNameActivityDetails();
  }, [getNameActivityDetails]);

  useEffect(() => {

    setFieldValue("actividadId",activityId)

    // Verificar si la fecha límite ha pasado
    if (activityLastDate) {

      const currentDate = dayjs().format("YYYY/MM/DD");

      const activityLatDay = dayjs(activityLastDate).format("YYYY/MM/DD")

     const evaluationDate = dayjs(activityLatDay).diff(currentDate,'day')

     const result = evaluationDate < 0;
     setActivityLastDatePassed(result)
    }
  }, [activityLastDate,setFieldValue]);


  return (
    <FormikForm>
      <Container component="main"  sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} >
          <Typography variant="h4" align="center" >
            {activityName}
          </Typography>


          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <CustomField
                name="tipo_documento"
                label="Seleccione el tipo de identificación"
                select
                fullWidth
                as={TextField}
                value={values?.tipo_documento || ''}
              >
                <MenuItem value="C">Cédula</MenuItem>
                <MenuItem value="P">Pasaporte</MenuItem>
                <MenuItem value="N/A">N/A</MenuItem>
              </CustomField>
            </Grid>


            <Grid item xs={12} sm={6}>
              <CustomField
                name="documento"
                value={values?.documento || ''}
                label="Número de identificación"
                fullWidth
                as={TextField}
                inputProps={{maxLength : 11}}
              />
            </Grid>


            <Grid item xs={12} sm={6}>
              <CustomField name="nombre" label="Nombre" fullWidth as={TextField} value={values?.nombre || ''} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomField name="apellido" label="Apellido" fullWidth as={TextField} value={values?.apellido || ''} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomField select as={TextField} name="genero" fullWidth label="Sexo" value={values?.genero || ''}>
                <MenuItem value="M">Masculino</MenuItem>
                <MenuItem value="F">Femenino</MenuItem>
                <MenuItem value="N/A">Prefiero no decir</MenuItem>
              </CustomField>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <CustomField name="email" label="Correo" fullWidth as={TextField} value={values?.email || ''} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomField
                name="celular"
                label="Celular"
                fullWidth
                as={TextField}
                value={values?.celular || ''}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomField
                name="institucion"
                label="Institución"
                fullWidth
                as={TextField}
                value={values?.institucion || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomField
                name="cargo"
                label="Cargo laboral"
                fullWidth
                as={TextField}
                value={values?.cargo || ""}
              />
            </Grid>


          </Grid>
          <Grid item sm={12} mt={3}>
            <Button variant="contained" type="submit" fullWidth disabled={ActivityLastDatePassed}>
              {isSubmitting && <CircularProgress size={'15px'} sx={{ color: 'white', marginRight: '6px' }} />}
              {isSubmitting ? 'Enviando' : 'Enviar'}
            </Button>
          </Grid>
        </Paper>
        <Grid>
          {ActivityLastDatePassed ? (
            <div>
              <p style={{ color: "red" }}>
              La fecha límite de este evento ha pasado, por favor contacte al correo electrónico.
              </p>

             
            </div>

          ) : <></>}
        </Grid>
      </Container>
      <Modal handleClose={() => { }} open={loadingDocuments}>
        <h1>Cargando..</h1>
      </Modal>
    </FormikForm>
  );
};

export default RegisterParticipantsForms;
