import * as Yup from "yup";

const initValuesActivities = {
anfitrion: "",
description: "",
actividad: "",
limitSpaces: false,
participantsNumber: 1,
lastEnrollDate: null
}

const validationSchemaActivities = Yup.object().shape({
    anfitrion: Yup.string(),
    actividad: Yup.string().required('El nombre de la actividad es obligatorio'),
    description: Yup.string().required("La descripción de la actividad es requerida"),
    lastEnrollDate: Yup.date().required('La fecha es obligatoria'),
    participantsNumber: Yup.number().required("Debe ser un número mayor o igual a 1"),
    limitSpaces: Yup.boolean()
})

export {initValuesActivities,validationSchemaActivities}
