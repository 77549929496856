
import React, { createContext, useContext } from 'react';
import notificationHook from "../components/notify/SnackNotification"

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const {SnackbarComponent,showSnackbar} = notificationHook();

  return (
    <NotificationContext.Provider value={{showSnackbar}}>
        <SnackbarComponent/>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification debe usarse dentro de un NotificationProvider');
  }
  return context;
};
