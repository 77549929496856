import * as Yup from "yup";

export const initialValuesUsers = {
    "names": "",
    "email": "",
    "is_active": true,
    "userType": "User"
}

export const validationSchemaUsers = Yup.object().shape({

    "names": Yup.string().required('El nombre del usuario es requerido.'),
    "email": Yup.string().required('El correo del usuario es requerido.'),
    "is_active": Yup.boolean()
})


