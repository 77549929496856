import { Formik } from 'formik';
import { initValuesSmtp, validationSchemaSmtp } from './createSmtpValidation';
import Form from "./SmtpConfigForm";
import { useAxios } from "../../../../context/AxiosHookContext";
import { useNotification } from '../../../../context/NotificationContext';

const SmtpForm = ({ onClose, valuesToEdit, getValues }) => {


    const getInitialValues = () => {
        return (valuesToEdit && Object.keys(valuesToEdit).length) > 0 ? valuesToEdit : initValuesSmtp
    }

    const {showSnackbar} = useNotification();

    const axios = useAxios();

    const handleSubmit = async (smtp) => {

        try {

            if (smtp._id) {
                await axios.put(`/smtp/${smtp._id}`, smtp);

            } else {
                await axios.post("/smtp", smtp);
            }

            showSnackbar({severity:"success",title:"Smtp",message: "Configuración guardada exitosamente."})

        } catch (error) {

            showSnackbar({severity:"error",title:"Smtp",message: "Ha ocurrido un error al guardar la configuración smtp."})

        } finally {
            getValues();

        }

    }

    return (
        <Formik
            validationSchema={validationSchemaSmtp}
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
        >
            <Form onClose={() => { getValues(); onClose() }} />
        </Formik>
    )
}

export default SmtpForm