import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

// @mui
import {
    Card,
    Paper,
    Stack,
    Button,
    Container,
    Typography,
    CircularProgress,
    TextField,
    MenuItem,
    Grid
} from '@mui/material';

// Cambios realizados para la actividad

// components
import Iconify from '../components/iconify';
// sections
// mock

// hooks
import { useAxios } from '../context/AxiosHookContext';
import { useNotification } from '../context/NotificationContext';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ManageCert() {

    const imageRef = useRef(null);
    const [text, setText] = useState("")
    const axiosHook = useAxios();
    const [identification, setIdentification] = useState("")
    const [loadingCert, setLoadingCert] = useState(false);
    const [activity, setActivity] = useState({});
    const { activityId } = useParams();
    const { showSnackbar } = useNotification();
    const ACTIVITY_API = `/activities/activities/public/${activityId}`;
    const [imageProperties, setImageProperties] = useState({
        textX: 0, // Posición X inicial
        textY: 50, // Posición Y inicial
        fontSize: 35,
        fontFamily: "Pacifico",
        colorFont: "#000000",
        base64Src: "",
        width: 0,
        height: 0
    });

    const handleAddText = useCallback((text) => {

        if (!imageProperties.base64Src) {
            return
        }

        const canvas = imageRef.current;

        if (!canvas) {
            return
        }
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.src = imageProperties.base64Src;
        img.onload = () => {
            ctx.drawImage(img, 0, 0);

            ctx.font = `${imageProperties.fontSize}px ${imageProperties.fontFamily}`; // Establece la fuente y el tamaño del texto
            ctx.fillStyle = imageProperties.colorFont; // Establece el color del texto

            // Calcula el ancho del texto en píxeles
            const textWidth = ctx.measureText(text).width;

            // Calcula la posición X de inicio para que el texto esté centrado
            const startX = ((canvas.width - textWidth) / 2) + imageProperties.textX;

            // Dibuja el texto en la posición calculada
            ctx.fillText(text, startX, imageProperties.textY);
        };
    }, [imageProperties]);


    const getActivityDetails = useCallback(async () => {
        try {

            const { data } = await axiosHook.get(ACTIVITY_API);
            setActivity({ ...activity, actividad: data.actividad })

        } catch (error) {
            showSnackbar({
                severity: "error",
                message: "Hemos encontrado un inconveniente al buscar los detalles de la actividad",
                title: "Lo sentimos"
            });
        }
    }, [axiosHook, ACTIVITY_API, activity, showSnackbar]);

    const getCert = async () => {
        try {

            setLoadingCert(true);

            const { data } = await axiosHook.get(`/activities/certificates/public/cert/${activityId}`)

            if (data.length > 0) {
                setImageProperties(data[0]);
            }

        } catch (error) {
            showSnackbar({
                severity: "info",
                message: "No existe un certificado para esta actividad",
                title: "Certificado:"
            });
        } finally {
            setLoadingCert(false);
        }
    }


    const searchCert = async () => {
        try {

            setLoadingCert(true);

            const { data } = await axiosHook.get(`/activities/certificates/public/cert/${activityId}/${identification}`)

            if (data.length > 0) {

                const participante = data[0];
                const text = `${capitalizeWords(participante.nombre)} ${capitalizeWords(participante?.apellido || '')}`;
                setText(text);
            } else {
                setText("");
                showSnackbar({
                    severity: "info",
                    message: "No existe un certificado con su identificación",
                    title: "Certificado:"
                });
            }

        } catch (error) {
            showSnackbar({
                severity: "info",
                message: "No existe un certificado con su identificación",
                title: "Certificado:"
            });
        } finally {
            setLoadingCert(false);
        }
    }


    const capitalizeWords = (text) => {
        // Divide la cadena en palabras
        const words = text.toLowerCase().split(' ');

        // Capitaliza la primera letra de cada palabra
        const capitalizedWords = words.map(word => {

            if (word.length > 0) {
                return word[0].toUpperCase() + word.slice(1);
            }

            return word; // Mantén palabras vacías como están

        });

        // Une las palabras en una sola cadena
        const capitalizedText = capitalizedWords.join(' ');

        return capitalizedText;
    }



    const downloaCert = () => {
        try {

            const canvas = imageRef.current;
            const link = document.createElement("a");
            link.download = `${text}.png`; // El nombre del archivo descargado
            link.href = canvas.toDataURL(); // Convierte el contenido del canvas a una URL de datos
            link.click(); // Simula un clic en el enlace para iniciar la descarga


            showSnackbar({
                severity: "success",
                message: "Se ha descargado correctament tu certificado.",
                title: "Felicidades:"
            });

        } catch (error) {
            showSnackbar({
                severity: "error",
                message: "Ha ocurrido un error al cargar tu certificado.",
                title: "Descargar:"
            });
        }
    }

    useEffect(() => {
        if (text) {
            handleAddText(text);
        }
    }, [text])

    useEffect(() => {

        if (activityId) {
            getActivityDetails();
            getCert();
        }
    }, [activityId])

    return (
        <>
            <Helmet>
                <title>Mi certificado | ASISCORE</title>
            </Helmet>

            <Container>

                <Stack direction="row" alignItems="center" justifyContent="center" mb={5} mt={3}>
                    {/* Stack */}
                    <Stack textAlign={"center"}>
                        <Typography component={"span"} fontSize={16}>
                            <Typography variant="h2" fontWeight={"bold"} >{activity && activity.actividad}</Typography>
                        </Typography>
                    </Stack>
                </Stack>


                {
                    imageProperties.base64Src &&
                    <Grid container sm={12} textAlign={"center"}>
                        <Grid item sm={12} alignItems={"center"}>
                            <TextField size="small" label="Identificación" onChange={(e) => setIdentification(e.target.value)} />
                            <SearchIcon size='40' sx={{ ml: 2, mt: 1, cursor: "pointer" }} onClick={searchCert} />
                        </Grid>

                        <Grid item sm={12} alignItems={"center"} mt={2}>
                            {text.length > 0 &&
                                <Button variant="contained" startIcon={<Iconify icon="ph:download-bold" />} onClick={downloaCert}>
                                    Descargar certificado
                                </Button>
                            }
                        </Grid>

                    </Grid>
                }

            </Container>

            {
                loadingCert && (
                    <Container sx={{ marginTop: 10 }}>
                        <Grid item sm={12} textAlign={"center"}>
                            <CircularProgress size={"30px"} sx={{ color: "#aa9e97", marginRight: "auto", marginLeft: "auto" }} />
                        </Grid>
                    </Container>
                )
            }


            <Card sx={{ width: imageProperties.width, height: imageProperties.height, marginLeft: "auto", marginRight: "auto", mt: 5 }}>
                <canvas ref={imageRef} width={imageProperties.width} height={imageProperties.height} />
            </Card>


            {!loadingCert && !imageProperties.base64Src &&

                <Container>
                    <Paper

                        sx={{
                            textAlign: 'center',
                            padding: 3,
                        }}
                    >

                        <Typography variant="h6" paragraph>
                            No existe un certificado
                        </Typography>

                        <Typography variant="body2" maxWidth={600} marginLeft={"auto"} marginRight={"auto"}>
                            El certificado de esta actividad aun esta en espera, por favor vuelve mas tarde.
                        </Typography>
                    </Paper>
                </Container>
            }

        </>
    );
}