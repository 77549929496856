import { Grid, TextField, Button, FormControlLabel, Switch } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Form as FormikForm, useFormikContext } from "formik";

import { CustomField } from '../../../inputs/CustomField';

export const CreateOrganizationForm = ({ onClose }) => {

    const { values, setFieldValue, isSubmitting } = useFormikContext();

    return (
        <FormikForm>
            <Grid container spacing={2} >

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="names" label="Nombre del usuario" fullWidth as={TextField} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="email" label="Correo" fullWidth as={TextField} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControlLabel
                        sx={{
                            display: 'block',
                        }}
                        control={
                            <Switch
                                checked={(values?.is_active || false)}
                                onChange={() => setFieldValue("is_active", !(values?.is_active || false))}
                                name="loading"
                                color="primary"
                            />
                        }
                        label={values?.is_active && 'Activado' || 'Desactivado'}
                    />
                </Grid>

            </Grid>

            <Grid item sm={12} textAlign={"right"} mt={3}>

                <Button
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    cancelar
                </Button>

                <Button sx={{ marginLeft: 2 }}
                    variant="contained"
                    type="submit"
                >
                    {isSubmitting && <CircularProgress size={"15px"} sx={{ color: "white", marginRight: "6px" }} />}
                    {isSubmitting ? 'Guardando' : 'Guardar'}
                </Button>

            </Grid>

        </FormikForm>
    );
}

export default CreateOrganizationForm;