import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  CircularProgress,
  Pagination
} from '@mui/material';

// cambios realizados para la actividad

import CustomModal from '../components/modal/CustomModal';

import FormCreateActivities from '../components/forms/activitiesForms/createActivity'

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
// sections
// mock


import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// hooks
import { useAxios } from '../context/AxiosHookContext';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'actividad', label: 'Actividad', alignRight: false },
  { id: 'anfitrion', label: 'Anfitrión', alignRight: false },
  { id: 'lastEnrollDate', label: 'Fecha límite', alignRight: false },
  { id: 'description', label: 'Descripción', alignRight: false },
  { id: 'limitSpaces', label: 'Cupos limitados', alignRight: false },
  { id: 'participantsNumber', label: 'Cant. Participantes', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------


export default function UserPage() {

  const [openModalActivities, setOpenModalActivities] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [activityList, setActivitiesList] = useState([])
  const [valuesToEdit, setValuesToEdit] = useState({})
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [filterName, setFilterName] = useState('');

  const ACTIVITY_API = `/activities/activities?&page=${page}&size=${10}&actividad=${filterName}`
  const axios = useAxios();


  const handleOpenMenu = (event, values) => {
    setOpen(event.currentTarget);
    setValuesToEdit(values)
  };


  const handleCloseMenu = () => {
    setOpen(null);
  };


  const getActivities = useCallback(async () => {
    try {
      setActivitiesList([])
      setLoading(true);
      const { data } = await axios.get(ACTIVITY_API);
      setActivitiesList(data.items);
      setTotalPages(data.total_pages)


    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [axios, ACTIVITY_API, setActivitiesList, setLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };


  useEffect(() => {
    getActivities();
  }, [getActivities, page, filterName])


  return (
    <>

      <Helmet>
        <title>Actividades | ASISCORE</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Actividades
          </Typography>
          <Button onClick={() => { setOpenModalActivities(true);setValuesToEdit({}) }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Nueva Actividad
          </Button>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead

                headLabel={TABLE_HEAD}

              />
              <TableBody>
                {activityList.length > 0 && activityList.map((row) => 

                   (
                    <TableRow hover key={row._id} tabIndex={-1} role="checkbox">

                      <TableCell component="th" scope="row" padding="normal">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Avatar alt={name} src={avatarUrl} /> */}
                          <Typography variant="subtitle2" noWrap>
                            {row.actividad}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell align="left">
                        {row.anfitrion || 'N/A'}
                      </TableCell>

                      <TableCell align="left">
                        <Label color={row.is_active === false ? 'error' : 'success'}>
                          {dayjs(row.lastEnrollDate).format('DD/MM/YYYY')}
                        </Label>
                      </TableCell>

                      <TableCell align="left">
                        {row.description || 'N/A'}
                      </TableCell>

                      <TableCell align="left">
                        {row.limitSpaces ? 'Si' : 'No'}
                      </TableCell>

                      <TableCell align="left">
                        {row.limitSpaces ? row.participantsNumber : 'Sin límites'}
                      </TableCell>

                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>

              {
                loading === true &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <CircularProgress size={"30px"} sx={{ color: "#aa9e97", marginRight: "auto", marginLeft: "auto" }} />

                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

              {
                (activityList.length === 0 && loading === false) &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <Typography variant="h6" paragraph>
                          Sin resultados
                        </Typography>

                        <Typography variant="body2">
                          No se encontraron resultados
                          <br /> Intente con otra búsqueda.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

            </Table>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}

            sx={{ float: "right", margin: 2, color: "#ffe7d9" }}
          />

        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 160,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => { setOpenModalActivities(true); setOpen(null) }} >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Editar
        </MenuItem>

        <MenuItem
          onClick={() => {
            window.navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN_URL}/addParticipants/${valuesToEdit._id}`);
            setOpen(null);
          }}>
          <Iconify icon={'eva:copy-fill'} sx={{ mr: 2 }} />
          Copiar enlace
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(`/activities/details/${valuesToEdit._id}`)
          }}>
          <Iconify icon={'pepicons-pop:people'} sx={{ mr: 2 }} />
          Ver registros
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(`/activities/cert/${valuesToEdit._id}`)
          }}>
          <Iconify icon={'fluent:certificate-24-filled'} sx={{ mr: 2 }} />
          Certificados
        </MenuItem>


      </Popover>


      <CustomModal
        open={openModalActivities}
        modalStyle={{ maxWidth: 800, minWidth: 440 }}
        title={"Formulario de Actividades"}
      >
        <FormCreateActivities
          onClose={() => setOpenModalActivities(false)}
          getValues={() => {
            getActivities();
            setOpenModalActivities(false);
            setValuesToEdit({});
            setOpen(null);
          }}
          valuesToEdit={valuesToEdit}
        />
      </CustomModal>
    </>
  );
}
