import { Formik } from 'formik';
import { validationSchemaUsers, initialValuesUsers } from './UsersFormValidator';
import Form from "./CreateUsersForm";
import { useAxios } from '../../../../context/AxiosHookContext';

const FormCreateUser = ({ valuesToEdit, onClose, getValues }) => {

    const axios = useAxios();


    const handleSubmit = async (user) => {

        try {

            if (user._id) {
                await axios.put(`/users/users/${user._id}`, user)
            } else {
                await axios.post("/users/users/create", user);
            }

        } catch (error) {
            console.log(error)
        } finally {
            getValues();
        }

    }

    const getInitialValues = () => {
        return (valuesToEdit && Object.keys(valuesToEdit).length) > 0 ? valuesToEdit : initialValuesUsers
    }
    return (
        <Formik
            validationSchema={validationSchemaUsers}
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
        >
            <>
                <Form onClose={onClose} />
            </>
        </Formik>
    )
}

export { FormCreateUser }