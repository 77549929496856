import { Grid, TextField, Button, Switch, FormControlLabel } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { Form as FormikForm, useFormikContext } from "formik";

import dayjs from 'dayjs';

import { CustomField } from '../../../inputs/CustomField';

export const ActivitiesForm = ({ handleChange, onClose }) => {

    const { values, setFieldValue, isSubmitting } = useFormikContext();

    return (
        <FormikForm>
            <Grid container spacing={2} >

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="actividad" label="Nueva Actividad" fullWidth as={TextField} value={values?.actividad || ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="anfitrion" label="Nombre del anfitrión" fullWidth as={TextField} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="description" multiline rows={3} label="Descripción del evento" fullWidth as={TextField} value={values?.description || ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>

                        <CustomField 
                        name="lastEnrollDate" 
                        fullWidth 
                        onChange={(e)=>{
                            setFieldValue("lastEnrollDate",e.$d)
                        }}
                        as={DatePicker} 
                        value={dayjs(values.lastEnrollDate)} 
                        label="Selecciona una fecha límite de inscripción"
                        slotProps={{ textField: { size: 'small', fullWidth: true } }} />

                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControlLabel
                        sx={{
                            display: 'block',
                        }}
                        control={
                            <Switch
                                checked={(values?.limitSpaces || false)}
                                onChange={() => setFieldValue("limitSpaces", !(values?.limitSpaces || false))}
                                name="limitSpaces"
                                color="primary"
                            />
                        }
                        label={values?.limitSpaces && 'No limitar cupos' || 'Limitar cupos.'}
                    />
                </Grid>

                {values.limitSpaces && (
                    <Grid item xs={12} sm={12} md={6} lg={6}
                    >
                        <CustomField
                            fullWidth
                            type="number"
                            label="Límite de participantes"
                            name="participantsNumber"
                            as={TextField}
                            value={values?.participantsNumber || 1}
                        />
                    </Grid>
                )}

            </Grid>

            <Grid item sm={12} textAlign={"right"} mt={3}>

                <Button
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    cancelar
                </Button>

                <Button sx={{ marginLeft: 2 }}
                    variant="contained"
                    type="submit"
                >
                    {isSubmitting && <CircularProgress size={"15px"} sx={{ color: "white", marginRight: "6px" }} />}
                    {isSubmitting ? 'Guardando' : 'Guardar'}
                </Button>

            </Grid>

        </FormikForm>

    );
}

export default ActivitiesForm;