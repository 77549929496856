import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  CircularProgress,
  Pagination
} from '@mui/material';

// cambios realizados para la actividad

import CustomModal from '../components/modal/CustomModal';

import FormCreateActivities from '../components/forms/activitiesForms/createActivity'

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
// sections
// mock


import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// hooks
import { useAxios } from '../context/AxiosHookContext';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'actividad', label: 'Código', alignRight: false },
  { id: 'actividad', label: 'Nombre', alignRight: false },
  { id: 'anfitrion', label: 'Apellido', alignRight: false },
  { id: 'description', label: 'Cédula', alignRight: false },
  { id: 'limitSpaces', label: 'Correo', alignRight: false },
  { id: 'limitSpaces', label: 'Coautor', alignRight: false },
  { id: 'limitSpaces', label: 'Pin', alignRight: false },
  { id: 'limitSpaces', label: 'Documento de identidad', alignRight: false },
  { id: 'limitSpaces', label: 'Certificado Universidad', alignRight: false },
  { id: 'limitSpaces', label: 'Autobiografía', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------


export default function TryoutList() {


  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [exporting,setExporting] = useState(false)
  const [activityList, setActivitiesList] = useState([])
  const [valuesToEdit, setValuesToEdit] = useState({})
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [filterName, setFilterName] = useState('');

  const ACTIVITY_API = `/tryout?&page=${page}&size=${10}&actividad=${filterName}`
  const axios = useAxios();


  const handleOpenMenu = (event, values) => {
    setOpen(event.currentTarget);
    setValuesToEdit(values)
  };


  const handleCloseMenu = () => {
    setOpen(null);
  };


  const goToFile = (url) => {
    window.open(`${process.env.REACT_APP_API_URL}/${url}`)
  }


  const exportExcel = async () => {
    try {
      setExporting(true)
      const response = await axios.get(`/tryout_report`, {
        responseType: 'blob' // Indica que esperas una respuesta de tipo Blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace <a> para iniciar la descarga
      const a = document.createElement('a');
      a.href = url;
      a.download = `reporte.xlsx`; // Nombre del archivo para descargar
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setExporting(false)
    } catch (error) {
      console.log(error)
      setExporting(false)
    }
  }


  const getActivities = useCallback(async () => {
    try {
      setActivitiesList([])
      setLoading(true);
      const { data } = await axios.get(ACTIVITY_API);
      setActivitiesList(data.list);
      setTotalPages(data.total_pages)
      setPage(data.current_page)


    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [axios, ACTIVITY_API, setActivitiesList, setLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };


  useEffect(() => {
    getActivities();
  }, [getActivities, page, filterName])


  return (
    <>

      <Helmet>
        <title>Concurso | ASISCORE</title>
      </Helmet>

      <Container>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Actividades
          </Typography>
          <Button variant="contained" onClick={exportExcel}>
            {exporting ? "Exportando registros" : "Exportar registros"}
          </Button>
        </Stack>

        <Card>
          {/* <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} /> */}

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead

                headLabel={TABLE_HEAD}

              />
              <TableBody>
                {activityList.length > 0 && activityList.map((row, key) =>

                (
                  <TableRow hover key={key} tabIndex={-1} role="checkbox">

                    <TableCell component="th" scope="row" padding="normal">
                      {row.enrollment || 'N/A'}
                    </TableCell>

                    <TableCell component="th" scope="row" padding="normal">
                      {row.nombre || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {row.apellido || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {row?.cedula || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {row?.correo || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {row?.coautor?.nombre || 'No'}
                    </TableCell>

                    <TableCell align="left">
                      {row?.pin || ''}
                    </TableCell>

                    <TableCell align="left">
                      {row?.documentoIdentidad ? <Button variant="contained" color="primary" onClick={() => goToFile(row.documentoIdentidad)}>Ver</Button> : "N/A"}

                    </TableCell>

                    <TableCell align="left">
                      {row?.certificacionUni ? <Button variant="contained" color="primary" onClick={() => goToFile(row.certificacionUni)}>Ver</Button> : "N/A"}
                    </TableCell>

                    <TableCell align="left">
                      {row?.autobiografia ? <Button variant="contained" color="primary" onClick={() => goToFile(row.autobiografia)}>Ver</Button> : "N/A"}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>

              {
                loading === true &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <CircularProgress size={"30px"} sx={{ color: "#aa9e97", marginRight: "auto", marginLeft: "auto" }} />

                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

              {
                (activityList.length === 0 && loading === false) &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <Typography variant="h6" paragraph>
                          Sin resultados
                        </Typography>

                        <Typography variant="body2">
                          No se encontraron resultados
                          <br /> Intente con otra búsqueda.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

            </Table>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}

            sx={{ float: "right", margin: 2, color: "#ffe7d9" }}
          />

        </Card>
      </Container>
      {/* 
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 160,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
       
        <MenuItem
          onClick={() => {
            window.navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN_URL}/addParticipants/${valuesToEdit._id}`);
            setOpen(null);
          }}>
          <Iconify icon={'eva:copy-fill'} sx={{ mr: 2 }} />
          Copiar enlace
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(`/activities/details/${valuesToEdit._id}`)
          }}>
          <Iconify icon={'pepicons-pop:people'} sx={{ mr: 2 }} />
          Ver registros
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(`/activities/cert/${valuesToEdit._id}`)
          }}>
          <Iconify icon={'fluent:certificate-24-filled'} sx={{ mr: 2 }} />
          Certificados
        </MenuItem>


      </Popover> */}


    </>
  );
}
