
import { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import WebFont from 'webfontloader';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import { AuthProvider } from "./context/AuthContext";
import AxiosContextProvider from "./context/AxiosHookContext";
import { NotificationProvider } from './context/NotificationContext';




const fontList = [
  "Pacifico",
  "Arial",
  "Young Serif",
  "Fira Sans",
  "Lobster",
  "Satisfy",
  "Permanent Marker",
  "Vollkorn",
  "Righteous",
  "Alfa Slab One",
  "Courgette",
  "Great Vibes"
];


export default function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: fontList,
      }
    });
  }, []);

  return (
      <HelmetProvider>
        <HashRouter>
          <NotificationProvider>
            <AuthProvider>
              <AxiosContextProvider>
                <ThemeProvider>
                  <ScrollToTop />
                  <StyledChart />
                  <Router />
                </ThemeProvider>
              </AxiosContextProvider>
            </AuthProvider>
          </NotificationProvider>
        </HashRouter>
      </HelmetProvider>
  );
}