import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'


// @mui
import {
    Card,
    Paper,
    Stack,
    Button,
    Container,
    Typography,
    CircularProgress,
    TextField,
    MenuItem,
    Grid
} from '@mui/material';

// Cambios realizados para la actividad

// components
import Iconify from '../components/iconify';
// sections
// mock

// hooks
import { useAxios } from '../context/AxiosHookContext';
import { useNotification } from '../context/NotificationContext';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const fontList = [
    "Pacifico",
    "Arial",
    "Young Serif",
    "Fira Sans",
    "Lobster",
    "Satisfy",
    "Permanent Marker",
    "Vollkorn",
    "Righteous",
    "Alfa Slab One",
    "Courgette",
    "Great Vibes"
];

export default function ManageCert() {

    const imageRef = useRef(null);
    const text = 'Joseph Joestar JoJo';
    const axiosHook = useAxios();
    const [loading, setLoading] = useState(false);
    const [loadingCert, setLoadingCert] = useState(false);
    const [activity, setActivity] = useState({});
    const { activityId } = useParams();
    const { showSnackbar } = useNotification();
    const ACTIVITY_API = `/activities/activities/public/${activityId}`;
    const [imageProperties, setImageProperties] = useState({
        textX: 0, // Posición X inicial
        textY: 50, // Posición Y inicial
        fontSize: 35,
        fontFamily: "Pacifico",
        colorFont: "#000000",
        base64Src: "",
        width: 0,
        height: 0
    });

    const handlePropertyChange = (property, value) => {
        setImageProperties(prevProperties => ({
            ...prevProperties,
            [property]: value
        }));
    };

    const handleAddText = useCallback(() => {

        if (!imageProperties.base64Src) {
            return
        }

        const canvas = imageRef.current;
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.src = imageProperties.base64Src;
        img.onload = () => {
            ctx.drawImage(img, 0, 0);

            ctx.font = `${imageProperties.fontSize}px ${imageProperties.fontFamily}`; // Establece la fuente y el tamaño del texto
            ctx.fillStyle = imageProperties.colorFont; // Establece el color del texto

            // Calcula el ancho del texto en píxeles
            const textWidth = ctx.measureText(text).width;

            // Calcula la posición X de inicio para que el texto esté centrado
            const startX = ((canvas.width - textWidth) / 2) + imageProperties.textX;

            // Dibuja el texto en la posición calculada
            ctx.fillText(text, startX, imageProperties.textY);
        };
    }, [imageProperties]);

    const onDrop = useCallback(acceptedFiles => {
        const [file] = acceptedFiles;


        const convertToBase64 = (file) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64String = `data:image/png;base64,${event.target.result.split(',')[1]}`; // Obtiene la cadena Base64

                const img = new Image();
                img.src = base64String;

                img.onload = () => {
                    const imageWidth = img.width; // Ancho de la imagen
                    const imageHeight = img.height; // Alto de la imagen

                    // Actualiza las propiedades de la imagen
                    setImageProperties({
                        ...imageProperties,
                        base64Src: base64String,
                        width: imageWidth,
                        height: imageHeight,
                    });
                };
            };

            reader.readAsDataURL(file);
        }

        // Convierte el archivo en Base64
        convertToBase64(file);


    }, [imageProperties])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop
    })

    const getActivityDetails = useCallback(async () => {
        try {

            const { data } = await axiosHook.get(ACTIVITY_API);
            setActivity({ ...activity, actividad: data.actividad })

        } catch (error) {
            showSnackbar({
                severity: "error",
                message: "Hemos encontrado un inconveniente al buscar los detalles de la actividad",
                title: "Lo sentimos"
            });
        }
    }, [axiosHook, ACTIVITY_API, activity, showSnackbar]);

    const saveCert = async () => {
        try {

            setLoading(true)

            if (!imageProperties.base64Src) {
                showSnackbar({
                    severity: "info",
                    message: "Debes subir un certificado base para poder guardar.",
                    title: "Verificar"
                });
                return
            }

            if (imageProperties._id) {
                await axiosHook.put(`/activities/certificates/${imageProperties._id}`, { ...imageProperties, activityId })
            } else {
                await axiosHook.post("/activities/certificates", { ...imageProperties, activityId })
            }

            showSnackbar({
                severity: "success",
                message: "Se ha guardado correctamente la configuración del certificado.",
                title: "Guardado"
            });

        } catch (error) {

            showSnackbar({
                severity: "error",
                message: "Hemos encontrado un inconveniente al guardar el certificado",
                title: "Lo sentimos"
            });

        } finally {
            setLoading(false);
            await getCert()

        }
    }

    const getCert = async () => {
        try {

            setLoadingCert(true);

            const { data } = await axiosHook.get(`/activities/certificates?activityId=${activityId}`)

            if (data.items.length > 0) {
                setImageProperties(data.items[0]);
            }

        } catch (error) {
            showSnackbar({
                severity: "error",
                message: "Hemos encontrado un inconveniente al obtener el certificado",
                title: "Lo sentimos"
            });
        } finally {
            setLoadingCert(false);
        }
    }


    const copyLink = () => {
        try {

            const link = `${process.env.REACT_APP_DOMAIN_URL}/getCert/${activityId}`
            window.navigator.clipboard.writeText(link);
            showSnackbar({
                severity: "success",
                message: "Se ha copiado el enlace del certificado, ahora puedes compartirlo.",
                title: "Compartir"
            });

        } catch (error) {
            showSnackbar({
                severity: "error",
                message: "Hemos encontrado un al obtener el enlace del certificado.",
                title: "Lo sentimos"
            });
        }
    }

    useEffect(() => {
        handleAddText();
    }, [handleAddText, imageProperties])

    useEffect(() => {

        if (activityId) {
            getActivityDetails();
            getCert();
        }
    }, [activityId])




    return (
        <>
            <Helmet>
                <title>Certificado | ASISCORE</title>
            </Helmet>


            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    {/* Stack y Button */}
                    <Stack>
                        <Typography variant="h4">
                            Certificado
                        </Typography>
                    </Stack>

                    <Button variant="contained" startIcon={<Iconify icon="tabler:certificate" />} onClick={saveCert}>
                        {loading && <CircularProgress size={'15px'} sx={{ color: 'white', marginRight: '6px' }} />}
                        {loading ? "Guardando" : "Guardar"}
                    </Button>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
                    {/* Stack */}
                    <Stack textAlign={"center"}>
                        <Typography component={"span"} fontSize={16}>
                            {"Actualmente estas configurando un certificado para la actividad:"} <Typography component="span" fontWeight={"bold"} >{activity && activity.actividad}</Typography>
                        </Typography>
                        { imageProperties && imageProperties.base64Src && 
                            <Button variant="contained" startIcon={<Iconify icon="material-symbols:share-outline" />} onClick={copyLink}>
                                Compartir certificado
                            </Button>
                        }
                    </Stack>
                </Stack>

                <Grid container mb={5} spacing={2}>

                    {/* Grid */}
                    <Grid item xs={12} sm={2} md={3} lg={2} >
                        <TextField
                            size="small"
                            type="number"
                            value={imageProperties.textY}
                            label="Posición Vertical"
                            fullWidth
                            onChange={(e) => { handlePropertyChange('textY', parseInt(e.target.value, 10)) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} >
                        <TextField
                            size="small"
                            type="number"
                            value={imageProperties.textX}
                            label="Posición Horizontal"
                            fullWidth
                            onChange={(e) => { handlePropertyChange('textX', parseInt(e.target.value, 10)) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3} >
                        <TextField
                            size="small"
                            select
                            type="text"
                            value={imageProperties.fontFamily}
                            label="Fuente"
                            fullWidth
                            onChange={(e) => { handlePropertyChange('fontFamily', e.target.value) }}
                        >
                            {fontList.map(font => (
                                <MenuItem key={font} value={font}>{font}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={2} >
                        <TextField
                            size="small"
                            type="number"
                            value={imageProperties.fontSize}
                            label="Tamaño de fuente"
                            fullWidth
                            onChange={(e) => { handlePropertyChange('fontSize', parseInt(e.target.value, 10)) }}
                        />
                    </Grid>
                    <Grid item container xs={12} sm={2} md={2} lg={3} spacing={1} alignItems={"center"} >
                        <Grid item sm={10}>
                            <TextField
                                size="small"
                                type="color"
                                value={imageProperties.colorFont}
                                label="Color de fuente"
                                fullWidth
                                onChange={(e) => { handlePropertyChange('colorFont', e.target.value) }}
                            />
                        </Grid>

                        <Grid item sm={2}>
                            <Iconify icon="system-uicons:reset" style={{
                                color: "blue",
                                cursor: "pointer"
                            }}
                                onClick={() => setImageProperties({ ...imageProperties, base64Src: "" })}
                            />
                        </Grid>

                    </Grid>

                </Grid>


            </Container>

            {
                loadingCert && (
                    <Container sx={{ marginTop: 10 }}>
                        <Grid item sm={12} textAlign={"center"}>
                            <CircularProgress size={"30px"} sx={{ color: "#aa9e97", marginRight: "auto", marginLeft: "auto" }} />
                        </Grid>
                    </Container>
                )
            }

            {
                !loadingCert && imageProperties.base64Src && (
                    <Card sx={{ width: imageProperties.width, height: imageProperties.height }}>
                        {imageProperties.base64Src && <canvas ref={imageRef} width={imageProperties.width} height={imageProperties.height} />}
                    </Card>
                )
            }


            {!loadingCert && !imageProperties.base64Src &&

                <Container>
                    <Paper
                        {...getRootProps()}

                        sx={{
                            textAlign: 'center',
                            border: "dashed",
                            borderWidth: "2px",
                            padding: 3,
                            cursor: "pointer"
                        }}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="h6" paragraph>
                            No existe un certificado
                        </Typography>

                        <Typography variant="body2" maxWidth={600} marginLeft={"auto"} marginRight={"auto"}>
                            Por favor carga un certificado base para configurar. Puedes hacer click aquí para cargarlo o soltarlo en este cuadro.
                        </Typography>
                    </Paper>
                </Container>
            }

        </>
    );
}