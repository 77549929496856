import * as Yup from "yup";

const initialValuesOrganizations = {
    "names": "",
    "email": "",
    "is_active": true,
    "userType": "Client"
}

const validationSchemaOrganizations = Yup.object().shape({

    "names": Yup.string().required('El nombre de la organización es requerido.'),
    "email": Yup.string().required('El correo de la organización es requerido.'),
    "is_active": Yup.boolean()
})



export {validationSchemaOrganizations, initialValuesOrganizations}