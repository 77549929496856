import { Grid, TextField, Button} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Form as FormikForm, useFormikContext } from "formik";

import { CustomField } from '../../../inputs/CustomField';

export const SmtpForm = ({ onClose }) => {

    const { values, isSubmitting } = useFormikContext();

    return (
        <FormikForm>
            <Grid container spacing={2} >

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="server" label="Servidor / Dominio" fullWidth as={TextField} value={values?.server || ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="port" label="Puerto" fullWidth as={TextField} value={values?.port || ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="username" label="Usuario smtp" fullWidth as={TextField} value={values?.username || ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomField name="password" label="Clave / contraseña" fullWidth as={TextField} value={values?.password || ""} />
                </Grid>

            </Grid>

            <Grid item sm={12} textAlign={"right"} mt={3}>

                <Button
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    cancelar
                </Button>

                <Button sx={{ marginLeft: 2 }}
                    variant="contained"
                    type="submit"
                >
                    {isSubmitting && <CircularProgress size={"15px"} sx={{ color: "white", marginRight: "6px" }} />}
                    {isSubmitting ? 'Guardando' : 'Guardar'}
                </Button>

            </Grid>

        </FormikForm>

    );
}

export default SmtpForm;