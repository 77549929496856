import { Formik } from 'formik';
import { validationSchemaActivities, initValuesActivities } from './createActivityValidation';
import Form from "./ActivitiesForm";
import { useAxios } from "../../../../context/AxiosHookContext";

const FormCreateActivity = ({ onClose,valuesToEdit , getValues})=>{


    const getInitialValues = () => {
        return (valuesToEdit && Object.keys(valuesToEdit).length) > 0 ? valuesToEdit : initValuesActivities
    }

    const axios = useAxios();

    const handleSubmit = async (activity) => {

        try {

            if (activity._id) {
                await axios.put(`/activities/assistants/${activity._id}`, activity)
            } else {
                await axios.post("/activities/activities", activity);
            }

        } catch (error) {
            console.log(error)
        } finally {
          getValues();
        }

    }

return (
    <Formik
    validationSchema={validationSchemaActivities}
    initialValues={getInitialValues()}
    onSubmit={handleSubmit}
    >
        <Form onClose={()=>{getValues();onClose()}}/> 
    </Formik>
)
}

export default FormCreateActivity