import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  CircularProgress,
  Pagination
} from '@mui/material';

// components
import Iconify from '../components/iconify';
import CustomModal from '../components/modal/CustomModal';

// hooks
import { useAxios } from '../context/AxiosHookContext';

// sections
import { UserListHead } from '../sections/@dashboard/user';

// form
import SmtpForm from "../components/forms/smtp/smtpConfig"
import { useNotification } from '../context/NotificationContext';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'server', label: 'Servidor', alignRight: false },
  { id: 'port', label: "Puerto", alignRight: false },
  { id: 'user', label: 'Usuario', alignRight: false },
  { id: 'pass', label: 'Clave', alignRight: false },
];

// ----------------------------------------------------------------------


export default function UserPage() {

  const { showSnackbar } = useNotification();
  const [open, setOpen] = useState(null);


  const [openModalConfSmtp, setOpenModalSmtpConf] = useState(false);

  const [loading, setLoading] = useState(false);
  const [smtp, setSmtp] = useState(null);
  const [valuesToEdit, setValuesToEdit] = useState({});

  const SMTP_API = `/smtp`;
  const axios = useAxios();

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };


  const getSmtp = useCallback(async () => {
    try {

      setSmtp(null);
      setLoading(true);

      const { data } = await axios.get(SMTP_API);
      if(data.items.length > 0){
        
        setSmtp(data.items[0]);
      }

      setLoading(false);

    } catch (error) {

      showSnackbar({severity: "error",title:"Error",message: "Ha ocurrido un error al obtener la configuración smtp del sistema."})

    }
    
  }, [axios, SMTP_API, setSmtp, setLoading, showSnackbar]);


  useEffect(() => {
    getSmtp();
  }, [getSmtp])


  return (
    <>
      <Helmet>
        <title>SMTP | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Servidor smtp
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon={`eva:${smtp?._id ? 'edit' : 'plus'}-fill` } />} onClick={() => { setValuesToEdit(smtp); setOpenModalSmtpConf(true) }}>
            {smtp?._id ? "Editar" : "Agregar"} smtp
          </Button>
        </Stack>

        <Card>

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead

                headLabel={TABLE_HEAD}

              />
              <TableBody>
                {smtp && smtp._id &&
                  (
                    <TableRow hover role="checkbox">

                      <TableCell align="left">
                        {smtp.server}
                      </TableCell>

                      <TableCell align="left">
                        {smtp.port}
                      </TableCell>

                      <TableCell align="left">
                        {smtp.username}
                      </TableCell>


                      <TableCell align="left">
                        {smtp.password}
                      </TableCell>

                    </TableRow>
                  )}
              </TableBody>

              {
                loading === true &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <CircularProgress size={"30px"} sx={{ color: "#aa9e97", marginRight: "auto", marginLeft: "auto" }} />

                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

              {
                (!smtp && loading === false) &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <Typography variant="h6" paragraph>
                          Sin resultados
                        </Typography>

                        <Typography variant="body2">
                          No se encontraron servidores smtp.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

            </Table>
          </TableContainer>

        </Card>
      </Container>

      <CustomModal
        open={openModalConfSmtp}
        modalStyle={{ maxWidth: 800, minWidth: 440 }}
        title={"Servidor de SMTP"}
      >
        <SmtpForm
          onClose={() => setOpenModalSmtpConf(false)}
          getValues={() => {
            getSmtp();
            setOpenModalSmtpConf(false);
            setValuesToEdit({});
            setOpen(null);
          }}
          valuesToEdit={valuesToEdit}
        />
      </CustomModal>
    </>
  );
}