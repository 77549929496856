import { Field, ErrorMessage } from "formik";

import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const CustomError = ({ name, customErrorText }) => (
    <ErrorMessage name={name}>
        {(formikError) => (
            <>
                {customErrorText && (
                    <span style={{ color: "red"}}>{customErrorText}</span>
                )}
                {formikError && (
                    <span style={{ color: "red",fontSize:"15px" }} ><ReportProblemIcon style={{ fontSize: '13px',marginRight:"3px",marginTop:"3px" }} />{formikError}</span>
                )}
            </>
        )}
    </ErrorMessage>
);


export const CustomField = ({ name, customErrorText, ...props }) => {
    return (
        <>
            <Field size="small" variant="outlined" name={name} style={{
                borderColor: customErrorText ? "red" : "",
            }} {...props}  />

            <CustomError name={name} customErrorText={customErrorText} />
        </>
    );
};

export default CustomField;
