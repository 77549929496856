import { createContext, useContext } from 'react';
import axios from "axios";
import { useAuth } from "./AuthContext";

const axiosInstance = (token) => {
  
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // Agregar un interceptor para manejar errores de respuesta
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        window.location.replace('/#/login');
      }
      return Promise.reject(error);
    }
  );

  return instance;
}

const AxiosContext = createContext(axiosInstance);

export function useAxios() {
  return useContext(AxiosContext);
}

export default function AxiosContextProvider ({ children }) {
  const { token } = useAuth();
  return <AxiosContext.Provider value={axiosInstance(token)}>{children}</AxiosContext.Provider>
};
