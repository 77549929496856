import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Pagination,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  CircularProgress
} from '@mui/material';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import CustomModal from '../components/modal/CustomModal';


// hooks

import { useAxios } from '../context/AxiosHookContext';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// form
import { FormCreateUser } from "../components/forms/usersForms/createUsers"

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'email', label: "Correo", alignRight: false },
  { id: 'status', label: 'Estatus', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function UsersPage() {

  const [open, setOpen] = useState(null);
  const [filterName, setFilterName] = useState('');

  const [openModaOrganizations, setOpenModalOrganizations] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [usersList, setusersList] = useState([])
  const [valuesToEdit, setValuesToEdit] = useState({})

  const ORG_API = `/users/users?userType=User&page=${page}&size=${10}&names=${filterName}`
  const axios = useAxios();

  const handleOpenMenu = (event, values) => {
    setOpen(event.currentTarget);
    setValuesToEdit(values)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };


  const getOrgs = useCallback(async () => {
    try {
      setusersList([])
      setLoading(true);
      const { data } = await axios.get(ORG_API);
      setusersList(data.items);
      setTotalPages(data.total_pages)

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [axios, ORG_API, setusersList, setLoading]);


  useEffect(() => {
    getOrgs();
  }, [getOrgs,filterName])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Usuarios | ASISCORE</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuarios
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => { setValuesToEdit({}); setOpenModalOrganizations(true) }}>
            Nuevo usuario
          </Button>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                headLabel={TABLE_HEAD}
              />
              <TableBody>
                {usersList?.map((row) => 

                   (
                    <TableRow hover key={row._id} tabIndex={-1} role="checkbox">

                      <TableCell component="th" scope="row" padding="normal">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="subtitle2" noWrap>
                            {row.names}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell align="left">
                        {row.email}
                      </TableCell>

                      <TableCell align="left">
                        <Label color={row.is_active === false ? 'error' : 'success'}>{row.is_active ? 'Activo' : 'Desactivado'}</Label>
                      </TableCell>


                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>

              {
                loading === true &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <CircularProgress size={"30px"} sx={{ color: "#aa9e97", marginRight: "auto", marginLeft: "auto" }} />

                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

              {
                (usersList.length === 0 && loading === false) &&
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >

                        <Typography variant="h6" paragraph>
                          Sin resultados
                        </Typography>

                        <Typography variant="body2">
                          No se encontraron resultados para
                          <br /> Intente con otra búsqueda.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              }

            </Table>
          </TableContainer>

          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            
            sx={{ float: "right", margin: 2,color:"#ffe7d9" }}
          />


        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => { setOpenModalOrganizations(true); setOpen(null) }} >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Editar
        </MenuItem>
      </Popover>

      <CustomModal
        open={openModaOrganizations}
        modalStyle={{ maxWidth: 800, minWidth: 440 }}
        title={"Formulario de usuarios"}
      >
        <FormCreateUser
          onClose={() => setOpenModalOrganizations(false)}
          getValues={() => {
            getOrgs();
            setOpenModalOrganizations(false);
            setValuesToEdit({});
            setOpen(null);
          }}
          valuesToEdit={valuesToEdit}
        />
      </CustomModal>
    </>
  );
}
