import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { useAuth } from '../../../context/AuthContext';

export default function RecoverPasswordForm() {
  const navigate = useNavigate();
  const { getVerificationCode, resetPassword, } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [newPassword, setNewPassword] = useState(false);

  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { tempToken } = useParams();


  const handleVerificationCode = async () => {
    try {

      if (password !== confirmationPassword) {
        setError("Las claves no coinciden.")
        return
      }
      setLoading(true);
      await resetPassword({ token: tempToken, password });
      navigate("/login");
    } catch (error) {
      setError("Por favor verifique sus credenciales e intente nuevamente.");
    } finally {
      setLoading(false)
    }
  };


  const handleSetNewPass = async () => {
    try {
      setLoading(true);
      await getVerificationCode({ email: username });
      setError("Por favor siga las instrucciones que le hemos enviado a su correo electronico.");
    } catch (error) {
      setError("Por favor verifique sus credenciales e intente nuevamente.");
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Stack spacing={3}>
        {
          !tempToken && <TextField name="email" label="Email address" onChange={(e) => { setUsername(e.target.value) }} />

        }

        {

          tempToken && (
            <>
              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => { setPassword(e.target.value) }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                name="password"
                label="Confirmar clave"
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => {
                  setConfirmationPassword(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )
        }
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2, cursor: "pointer" }} onClick={() => navigate('/login')}>
        <Link variant="subtitle2" underline="hover">
          ¿Iniciar sesión?
        </Link>
      </Stack>

      {!error &&
        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={tempToken ? handleVerificationCode : handleSetNewPass}
          disabled={username ? false : (!password || (tempToken && (password !== confirmationPassword)))}
        >
          {loading ? 'Enviando solicitud' : tempToken ? 'Guardar nueva clave' : 'Enviar instrucciones al correo'}
        </LoadingButton>
      }

      < Typography variant="subtitle2" sx={{ textAlign: 'center', color: "red" }}>
        {error ? `${error}` : password !== confirmationPassword ? 'Las claves no coinciden' : ''}
      </Typography>

    </>
  );
}
