import * as Yup from "yup";

const initValuesActivities = {
    asiento: ""
}

const validationSchemaActivities = Yup.object().shape({
    asiento: Yup.string().required("Es necesario agregar un asiento para guardar")
})

export { initValuesActivities, validationSchemaActivities }
