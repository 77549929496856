import {useParams } from 'react-router-dom';
import { Formik } from 'formik';
import axios from "axios"
import { validationSchemaParticipants, initValuesParticipants } from './createParticipantsValidation';
import Form from "./RegisterParticipantsForms";

import { useNotification } from '../../../../context/NotificationContext';

const FormRegisterParticipants = ({ onClose }) => {
  const { showSnackbar } = useNotification();

  const { activityId } = useParams();

  const handleSubmit = async (e,{resetForm}) => {
    try {
      e.actividadId = activityId
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/activities/assistants/add`, e);
      resetForm();
      showSnackbar({title: "Registrado", message: response.data.message,severity: "success"}); // Mostrar Snackbar en caso de error

    } catch (error) {

    const message = error?.response?.data?.message || error?.message || "Ha ocurrido un error al registrarte a esta actividad."
      showSnackbar({title: "Error", message,severity: "error"}); // Mostrar Snackbar en caso de error
    }
  }

  return (
    <>
      <Formik
        validationSchema={validationSchemaParticipants}
        initialValues={initValuesParticipants}
        onSubmit={handleSubmit}
        rese
      >
        <Form
          onClose={() => { onClose() }}
        />
      </Formik>
    </>
  )
}

export default FormRegisterParticipants;
