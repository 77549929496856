import { Grid, TextField, Button, Switch, FormControlLabel } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { Form as FormikForm, useFormikContext } from "formik";

import dayjs from 'dayjs';

import { CustomField } from '../../../inputs/CustomField';

export const ActivitiesForm = ({ handleChange, onClose }) => {

    const { values, setFieldValue, isSubmitting } = useFormikContext();

    return (
        <FormikForm>
            <Grid container spacing={2} >

                <Grid item  sm={12}>
                    <CustomField name="asiento" label="Agregar # de asiento" fullWidth as={TextField} value={values?.asiento || ""} />
                </Grid>

            </Grid>

            <Grid item sm={12} textAlign={"right"} mt={3}>

                <Button
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    cancelar
                </Button>

                <Button sx={{ marginLeft: 2 }}
                    variant="contained"
                    type="submit"
                >
                    {isSubmitting && <CircularProgress size={"15px"} sx={{ color: "white", marginRight: "6px" }} />}
                    {isSubmitting ? 'Guardando' : 'Guardar'}
                </Button>

            </Grid>

        </FormikForm>

    );
}

export default ActivitiesForm;