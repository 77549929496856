import * as Yup from "yup";

const initValuesSmtp = {
    server: "",
    port: 587,
    username: "",
    password: ""
}

const validationSchemaSmtp = Yup.object().shape({
    server: Yup.string().required('Por favor colocar el servidor smtp.'),
    port: Yup.number().typeError("Debe ser un número de puerto válido.").required("Por favor colocar el número del puerto."),
    username: Yup.string().required('Por favor colocar el usuario smtp.'),
    password: Yup.string().required('Por favor colocar la clave del usuario smtp.')
})

export { initValuesSmtp, validationSchemaSmtp }