// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Organizaciones',
    path: '/organizations',
    icon: icon('ic_user'),
    userType: ['Admin'],
    permissions: []
  },
  {
    title: 'Actividades',
    path: '/activities',
    icon: icon('ic_user'),
    userType: ['User','Client'],
    permissions: []
  },
  // {
  //   title: 'Concursos',
  //   path: '/tryoutList',
  //   icon: icon('ic_user'),
  //   userType: ['User','Client'],
  //   permissions: []
  // },
  {
    title: 'Usuarios',
    path: '/users',
    icon: icon('ic_user'),
    userType: ['Client'],
    permissions: []
  },
  {
    title: 'Smtp',
    path: '/smtp',
    icon: icon('ic_mail'),
    userType: ['Admin'],
    permissions: []
  },
  
  // {
  //   title: 'Usuarios',
  //   path: 'user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'product',
  //   path: 'products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: 'blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
