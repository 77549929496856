import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Hook personalizado para manejar notificaciones
export default function NotificationHook() {
  const [props, setProps] = useState({
    open: false,
    title: "",
    severity: "success",
    message: "",
    duration: 6000
  });


  const showSnackbar = ({message,title, severity,duration=6000}) => {
    setProps({open: true,message, severity,duration,title});
  };

  const getStyles = (severity) =>{

    const baseColor = {
      width: 337,
      padding: "6px 10px"
    }

    if(severity === "success"){
      baseColor.backgroundColor = "#e7ffe7"
      baseColor.color = "#0f5132"
    }

    if(severity === "error"){
      baseColor.backgroundColor = "#ffdddd"
      baseColor.color = "#0f5132"
    }

    if(severity === "warning"){
      baseColor.backgroundColor = "#fff3cd"
      baseColor.color = "#0f5132"
    }
    return {...baseColor}
  }


  const SnackbarComponent = () => (
    <Snackbar open={props.open} autoHideDuration={props.duration}  onClose={()=>setProps({...props,open:false,message: false,title: ""})}
    anchorOrigin={{vertical:"top",horizontal:"left"}}
    >
      <MuiAlert variant='filled' severity={props.severity} style={{...getStyles(props.severity)}} >
        <span style={{fontWeight:"bold"}}>{props.title}</span>:  {props.message} 
      </MuiAlert>
    </Snackbar>
  );

  return { showSnackbar, SnackbarComponent };
}
