import * as Yup from "yup";

const initValuesParticipants = {
    tipo_documento: "C",
    documento: "",
    nombre: "",
    apellido: "",
    email: '',
    phone: "",
    institucion: '',
    cargo: "",
    asiento: "",
    genero: '',
    actividadId: ""
}

const validationSchemaParticipants = Yup.object().shape({
    tipo_documento: Yup.string().required("Es necesario colocar el tipo de documento."),
    documento: Yup.string().required("Es necesario colocar la identificación."),
    nombre: Yup.string().required("Es necesario colocar el nombre."),
    apellido: Yup.string(),
    email: Yup.string().email('Ingrese una dirección de correo electrónico válida'),
    celular: Yup.string(),
    institucion: Yup.string(),
    cargo: Yup.string().required("Por favor ingrese aquí su cargo."),
    asiento: Yup.string(),
    genero: Yup.string().required("Es necesario colocar el género."),
    actividadId: Yup.string()
})

export { initValuesParticipants, validationSchemaParticipants }