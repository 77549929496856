import { Grid, Typography, Button, Box, TextField, MenuItem, InputLabel, Checkbox } from "@mui/material";
import { useState } from "react";
import SchoolIcon from '@mui/icons-material/School';
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import { useNotification } from '../context/NotificationContext';
import CustomModal from '../components/modal/CustomModal';

const InputFile = ({ description, typeDocument, filledText, formData, setFormData }) => {

    const [loadng, setLoading] = useState(false);


    const handleChange = async (e) => {
        try {

            setLoading(true)
            const file = e.target.files[0];

            // Validar que solo se acepten archivos PDF
            if (file && file.type !== 'application/pdf') {
                alert('Por favor, seleccione un archivo PDF.');
                return;
            }

            const formDataLocal = new FormData();
            formDataLocal.append('file', file);
            formDataLocal.append('type', typeDocument);
            formDataLocal.append('enrollment', formData.enrollment);

            // Enviar el archivo al servidor
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/tryout/documents`, formDataLocal);
            setFormData({ ...formData, [typeDocument]: data })
            // Manejar la respuesta del servidor si es necesario
            setLoading(false)

        } catch (error) {
            setLoading(false)
            // Manejar errores si es necesario
            console.error('Error al enviar el archivo:', error);
        }
    };

    return (
        <>
            <InputLabel
                htmlFor={typeDocument}
                sx={{
                    border: "solid 1px black",
                    borderStyle: "dotted",
                    borderColor: "gray",
                    padding: 1,
                    textAlign: "center",
                    cursor: "pointer",
                    fontSize: 12,
                    ":hover": {
                        backgroundColor: "#a3a7ab",
                        color: "white"
                    }

                }}
            >

                {loadng ? "Cargando..." :
                    <>
                        {formData[typeDocument].length > 0 && <CheckIcon fontSize="10px" sx={{ mr: 1 }} />}
                        {formData[typeDocument].length > 0 ? filledText : description}
                    </>
                }


            </InputLabel>
            <TextField
                id={typeDocument}
                name={typeDocument}
                type="file"
                variant="standard"
                sx={{ display: "none" }}
                fullWidth
                accept=".pdf" // Aceptar solo archivos PDF
                onClick={(e) => { e.target.value = null }}
                onChange={handleChange}
            />
        </>
    );
};

const initialForm = {
    enrollment: "",
    pin: "",
    nombre: "",
    apellido: "",
    genero: "",
    cedula: "",
    nacionalidad: "",
    telefono: "",
    correo: "",
    universidad: "",
    carrera: "",
    semestre: "",
    direccion: "",
    ciudad: "",
    provincia: "",
    documentoIdentidad: "",
    certificacionUni: "",
    autobiografia: "",
    coautoria: "No",
    coautor: {
        nombre: "",
        apellido: "",
        genero: "",
        cedula: "",
        nacionalidad: "",
        telefono: "",
        correo: "",
        universidad: "",
        carrera: "",
        semestre: "",
        direccion: "",
        ciudad: "",
        provincia: ""
    }
}

const Tryout = () => {

    const [continueTryout, setContinue] = useState(null);
    const [enrollment, setEnrollment] = useState("")
    const [saving, setSaving] = useState(false)
    const [formData, setFormData] = useState(initialForm);
    const { showSnackbar } = useNotification();
    const [openModal, setOpenModal] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [showError, setShowError] = useState(false);
    const [pin, setPin] = useState("")

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleInputChangeCoautor = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            coautor: {
                ...prevState.coautor,
                [name]: value
            }
        }));
    };

    const getTryout = async () => {
        try {

            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tryout/one?enrollment=${enrollment}&pin=${pin}`)
            if (!data) {

                showSnackbar({
                    severity: "warning",
                    message: `No existe un concurso con el numero de inscripción ${enrollment} y el pin:  ${pin}`,
                    title: "Información"
                });

                return
            }
            const newData = { ...formData, ...data, enrollment: enrollment }
            setFormData(newData)
            setContinue(false)
            setIsNew(false)

        } catch (error) {
            showSnackbar({
                severity: "warning",
                message: error?.response?.data?.message || error?.data?.data?.message || error?.data?.response || "Ha ocurrido un error al guardar tu solicitud, por favor intenta nuevamente.",
                title: "Información"
            });
        }
    }

    const start = async () => {
       
        // try {

        //     const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/tryout/sequence`)

        //     setFormData({ ...formData, enrollment: data.data })
        //     setOpenModal(true)
        //     setContinue(false)

        // } catch (error) {
        //     console.log(error)
        // }
    }


    const cerrar = () => {
        setContinue(null)
        setFormData(initialForm)
        setOpenModal(false)
        setIsNew(true)
    }

    const save = async () => {
        try {

            let isIncompleted = false;

            Object.keys(formData).forEach(key => {

                if(key === "certificacionUni"){
                    return
                }


                if (formData[key].length < 1) {
                    isIncompleted = true
                }

                if (key === "coautoria" && formData[key] === "Si") {
                    const coautorData = formData.coautor;

                    Object.keys(coautorData).forEach(keyCoautor => {
                        if (coautorData[keyCoautor].length < 1) {
                            isIncompleted = true
                        }

                    });
                }

            });

            if (isIncompleted) {
                setShowError(true);
                return true;
            }

            setSaving(true);

            if(isNew){
                await axios.post(`${process.env.REACT_APP_API_URL}/tryout`, formData)
            } else {
                axios.put(`${process.env.REACT_APP_API_URL}/tryout/${formData.enrollment}`, formData)
            }

            setSaving(false);

            showSnackbar({
                severity: "success",
                message: "Se ha guardado correctamente.",
                title: "Guardado"
            });
            
            return false; // Se agrega el retorno explícito al final de la función

        } catch (error) {

            showSnackbar({
                severity: "info",
                message: error?.response?.data?.message || error?.data?.data?.message || error?.data?.response || "Ha ocurrido un error al guardar tu solicitud, por favor intenta nuevamente.",
                title: "Información"
            });

            setSaving(false);
            return false; // Se agrega el retorno explícito al final de la función
        } finally{
            setIsNew(false)
        }
    };

    const continuar = async () => {
        try {
            setShowError(false);
            const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/tryout/${formData.enrollment}`, formData);
            setOpenModal(false);

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box width={"100%"} display={"flex"} height={continueTryout === false ? "10px" : "100%"} justifyContent={"center"} alignItems={continueTryout === null ? "center" : "stretch"}   >
            <Grid container width={"100%"} xs={12} sm={12} md={6} lg={6} spacing={2} >
                {
                    continueTryout === null &&
                    (
                        <>
                            <Grid item xs={12} >
                                <Button variant="contained" color="secondary" sx={{ backgroundColor: "#05599f" }} fullWidth onClick={start} disabled>
                                    <SchoolIcon sx={{ mr: 2 }} />
                                    Nueva Inscripción
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" sx={{ backgroundColor: "#05599f" }} fullWidth onClick={() => setContinue(false)} disabled>
                                    <SchoolIcon sx={{ mr: 2 }} />
                                    Completar formulario de Inscripción</Button>
                            </Grid>
                        </>
                    )
                }

                {
                    continueTryout === false && formData.enrollment.length < 1 &&
                    <>
                        <TextField id="apellido" label="NUMERO DE INSCRIPCIÓN" variant="standard" fullWidth value={enrollment}
                            sx={{
                                textAlign: "center",
                                mt: 5
                            }}
                            onChange={(e) => { setEnrollment(e.target.value) }}
                        />

                        <TextField id="apellido" label="PIN DE RECUPERACIÓN" variant="standard" fullWidth value={pin}
                            sx={{
                                textAlign: "center",
                                mt: 1
                            }}
                            onChange={(e) => { setPin(e.target.value) }}
                        />

                        <Button variant="contained" color="primary" sx={{ backgroundColor: "#05599f", mt: 1 }} fullWidth onClick={getTryout}>
                            <SchoolIcon sx={{ mr: 2 }} />
                            CONTINUAR
                        </Button>
                    </>
                }

                {

                    continueTryout === false && formData.enrollment.length > 0 &&
                    <>

                        <Grid item xs={12} >
                            <Typography sx={{
                                color: "#0b2d4a",
                                fontSize: 40,
                                mt: 3,
                                fontWeight: "bold",
                                textAlign: "center"
                            }}>
                                FORMULARIO DE INSCRIPCIÓN

                                {formData.enrollment &&
                                    <>
                                        <br />
                                        {formData.enrollment}
                                    </>
                                }
                            </Typography>


                        </Grid>



                        <Grid item xs={12} >
                            <TextField id="nombre" name="nombre" label="Nombre*" variant="standard" fullWidth value={formData.nombre} onChange={handleInputChange} />
                            {showError && !formData.nombre && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                        </Grid>

                        <Grid item xs={12} >
                            <TextField id="apellido" name="apellido" label="Apellido*" variant="standard" fullWidth value={formData.apellido} onChange={handleInputChange} />
                            {showError && !formData.apellido && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                        </Grid>

                        <Grid item container xs={12} spacing={1}>

                            <Grid item xs={12} sm={12} md={6} >
                                <TextField id="genero" name="genero" label="Género*" variant="standard" select fullWidth value={formData.genero} onChange={handleInputChange}>
                                    <MenuItem value="Femenino">Femenino</MenuItem>
                                    <MenuItem value="Masculino">Masculino</MenuItem>
                                </TextField>
                                {showError && !formData.genero && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} >
                                <TextField id="cedula" name="cedula" label="Cédula / Pasaporte*" variant="standard" fullWidth value={formData.cedula} onChange={handleInputChange} />
                                {showError && !formData.cedula && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                        </Grid>

                        <Grid item container xs={12} spacing={1}>

                            <Grid item xs={12} sm={12} md={4} >
                                <TextField id="nacionalidad" name="nacionalidad" label="Nacionalidad*" variant="standard" fullWidth value={formData.nacionalidad} onChange={handleInputChange} />
                                {showError && !formData.nacionalidad && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} >
                                <TextField id="telefono" name="telefono" label="Teléfono*" variant="standard" fullWidth value={formData.telefono} onChange={handleInputChange} />
                                {showError && !formData.telefono && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                            <Grid item xs={12} sm={12} md={5} >
                                <TextField id="correo" name="correo" label="Correo*" variant="standard" fullWidth value={formData.correo} onChange={handleInputChange} />
                                {showError && !formData.correo && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                        </Grid>

                        <Grid item container xs={12} spacing={1}>

                            <Grid item xs={12} sm={12} md={6} >
                                <TextField id="universidad" name="universidad" label="Universidad*" variant="standard" fullWidth value={formData.universidad} onChange={handleInputChange} />
                                {showError && !formData.universidad && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} >
                                <TextField id="carrera" name="carrera" label="Carrera*" variant="standard" fullWidth value={formData.carrera} onChange={handleInputChange} />
                                {showError && !formData.carrera && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                        </Grid>

                        <Grid item xs={12} >
                            <TextField id="semestre" name="semestre" label="Semestre / Cuatrimestre / Trimestre*" variant="standard" fullWidth value={formData.semestre} onChange={handleInputChange} />
                            {showError && !formData.semestre && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                        </Grid>

                        <Grid item xs={12} >
                            <TextField id="direccion" name="direccion" label="Dirección*" variant="standard" fullWidth value={formData.direccion} onChange={handleInputChange} />
                            {showError && !formData.direccion && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                        </Grid>

                        <Grid item container xs={12} spacing={1}>

                            <Grid item xs={12} sm={12} md={6} >
                                <TextField id="ciudad" name="ciudad" label="Ciudad*" variant="standard" fullWidth value={formData.ciudad} onChange={handleInputChange} />
                                {showError && !formData.ciudad && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} >
                                <TextField id="provincia" name="provincia" label="Provincia*" variant="standard" fullWidth value={formData.provincia} onChange={handleInputChange} />
                                {showError && !formData.provincia && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                            </Grid>

                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>
                                Coautoría
                                <Checkbox checked={formData.coautoria === "Si" ? true : false} onChange={(e) => setFormData({ ...formData, coautoria: e.target.checked ? "Si" : "No" })} />
                            </InputLabel>
                        </Grid>

                        {
                            formData.coautoria === "Si" &&

                            <>

                                <Grid item xs={12} >
                                    <TextField id="nombre" name="nombre" label="Nombre coautor*" variant="standard" fullWidth value={formData.coautor.nombre} onChange={handleInputChangeCoautor} />
                                    {showError && !formData.coautor.nombre && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                </Grid>

                                <Grid item xs={12} >
                                    <TextField id="apellido" name="apellido" label="Apellido coautor*" variant="standard" fullWidth value={formData.coautor.apellido} onChange={handleInputChangeCoautor} />
                                    {showError && !formData.coautor.apellido && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                </Grid>

                                <Grid item container xs={12} spacing={1}>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextField id="genero" name="genero" label="Género coautor*" variant="standard" select fullWidth value={formData.coautor.genero} onChange={handleInputChangeCoautor}>
                                            <MenuItem value="Femenino">Femenino</MenuItem>
                                            <MenuItem value="Masculino">Masculino</MenuItem>
                                        </TextField>
                                        {showError && !formData.coautor.genero && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextField id="cedula" name="cedula" label="Cédula / Pasaporte coautor*" variant="standard" fullWidth value={formData.coautor.cedula} onChange={handleInputChangeCoautor} />
                                        {showError && !formData.coautor.cedula && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                </Grid>

                                <Grid item container xs={12} spacing={1}>

                                    <Grid item xs={12} sm={12} md={4} >
                                        <TextField id="nacionalidad" name="nacionalidad" label="Nacionalidad coautor*" variant="standard" fullWidth value={formData.coautor.nacionalidad} onChange={handleInputChangeCoautor} />
                                        {showError && !formData.coautor.nacionalidad && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={3} >
                                        <TextField id="telefono" name="telefono" label="Teléfono coautor*" variant="standard" fullWidth value={formData.coautor.telefono} onChange={handleInputChangeCoautor} />
                                        {showError && !formData.coautor.telefono && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={5} >
                                        <TextField id="correo" name="correo" label="Correo coautor*" variant="standard" fullWidth value={formData.coautor.correo} onChange={handleInputChangeCoautor} />
                                        {showError && !formData.coautor.correo && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                </Grid>

                                <Grid item container xs={12} spacing={1}>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextField id="universidad" name="universidad" label="Universidad coautor*" variant="standard" fullWidth value={formData.coautor.universidad} onChange={handleInputChangeCoautor} />
                                        {showError && !formData.coautor.universidad && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextField id="carrera" name="carrera" label="Carrera coautor*" variant="standard" fullWidth value={formData.coautor.carrera} onChange={handleInputChangeCoautor} />
                                        {showError && !formData.coautor.carrera && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                </Grid>

                                <Grid item xs={12} >
                                    <TextField id="semestre" name="semestre" label="Semestre / Cuatrimestre / Trimestre coautor*" variant="standard" fullWidth value={formData.coautor.semestre} onChange={handleInputChangeCoautor} />
                                    {showError && !formData.coautor.semestre && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                </Grid>

                                <Grid item xs={12} >
                                    <TextField id="direccion" name="direccion" label="Dirección coautor*" variant="standard" fullWidth value={formData.coautor.direccion} onChange={handleInputChangeCoautor} />
                                    {showError && !formData.coautor.direccion && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                </Grid>

                                <Grid item container xs={12} spacing={1}>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextField id="ciudad" name="ciudad" label="Ciudad coautor*" variant="standard" fullWidth value={formData.coautor.ciudad} onChange={handleInputChangeCoautor} />
                                        {showError && !formData.coautor.ciudad && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextField id="provincia" name="provincia" label="Provincia coautor*" variant="standard" fullWidth value={formData.coautor.provincia} onChange={handleInputChangeCoautor} />
                                        {showError && !formData.coautor.provincia && <span style={{ color: "red", fontSize: 14 }}>Este campo es obligatorio*</span>}
                                    </Grid>

                                </Grid>

                            </>
                        }

                        <Typography p={2} variant="h6">Cargar los documentos en formato pdf, en caso de existir coautor pueden cargarlos en el mismo pdf ambos documentos.</Typography>

                        <Grid item xs={12} >
                            <InputFile
                                description={"CARGAR DOCUMENTO DE IDENTIDAD EN PDF *"}
                                typeDocument={"documentoIdentidad"}
                                filledText={"SE HA CARGADO UN DOCUMENTO DE IDENTIDAD, CARGAR DE NUEVO?"}
                                formData={formData}
                                setFormData={setFormData}
                            />
                             {showError && !formData.documentoIdentidad && <span style={{ color: "red", fontSize: 14 }}>Este documento es obligatorio*</span>}
                        </Grid>

                        <Grid item xs={12} >
                            <InputFile
                                description={"CARGAR CERTIFICACIÓN DE ESTUDIANTE UNIVERSITARIO EN PDF *"}
                                typeDocument={"certificacionUni"}
                                filledText={"SE HA CARGADO UN DOCUMENTO DE CERTIFICACIÓN, CARGAR DE NUEVO?"}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            
                        </Grid>

                        <Grid item xs={12} >
                            <InputFile
                                description={"CARGAR AUTOBIOGRAFÍA EN PDF *"}
                                typeDocument={"autobiografia"}
                                filledText={"SE HA CARGADO UN DOCUMENTO DE AUTOBIOGRAFÍA, CARGAR DE NUEVO?"}
                                formData={formData}
                                setFormData={setFormData}
                            />
                           {showError && !formData.autobiografia && <span style={{ color: "red", fontSize: 14 }}>Este documento es obligatorio*</span>}
                        </Grid>

                        <Grid item xs={12} >
                            <Button variant="contained" color="secondary" sx={{ backgroundColor: "#05599f", mb: 2 }} fullWidth onClick={save}>
                                <SchoolIcon sx={{ mr: 2 }} />
                                {saving ? "Guardando cambios" : "Guardar cambios"}
                            </Button>

                            <Button variant="contained" color="secondary" sx={{ backgroundColor: "#05599f", mb: 2 }} fullWidth onClick={cerrar}>
                                Salir
                            </Button>
                        </Grid>

                    </>
                }
            </Grid>

            <CustomModal open={openModal} modalStyle={{ maxWidth: 440, minWidth: 440 }}>

                <Typography>Gracias por participar en nuestro concurso anual, su número de inscripción es: <span style={{ fontWeight: "bold" }}>{formData.enrollment}</span>,
                    por favor guárdelo y complete el formulario siguiente.</Typography>

                <br />
                <Typography>Por favor agrega un pin para proteger tu formulario de inscripción</Typography>

                <TextField id="pin" name="pin" label="Agregar pin de recuperación" variant="standard" fullWidth value={formData.pin} onChange={handleInputChange} autoFocus />

                <Button variant="contained" color="secondary" sx={{ backgroundColor: "#05599f", mb: 2, mt: 2, float: "right" }} fullWidth onClick={continuar} disabled={!formData.pin}>
                    Continuar
                </Button>

            </CustomModal>
        </Box >
    )
}

export default Tryout;
