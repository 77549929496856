import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";


export default function ValidateAuth({ component: Component }) {

    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Navigate to="/login" />
    }

    return (<Component />);

}
