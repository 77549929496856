import { Formik } from 'formik';
import { validationSchemaOrganizations, initialValuesOrganizations } from './OrganizationsFormValidator';
import Form from "./CreateOrganizationForm";
import { useAxios } from '../../../../context/AxiosHookContext';

const FormCreateOrganization = ({ valuesToEdit, onClose, getValues }) => {

    const axios = useAxios();


    const handleSubmit = async (organization) => {

        try {

            if (organization._id) {
                await axios.put(`/users/users/${organization._id}`, organization)
            } else {
                await axios.post("/users/users/create", organization);
            }

        } catch (error) {
            console.log(error)
        } finally {
            getValues();
        }

    }

    const getInitialValues = () => {
        return (valuesToEdit && Object.keys(valuesToEdit).length) > 0 ? valuesToEdit : initialValuesOrganizations
    }
    return (
        <Formik
            validationSchema={validationSchemaOrganizations}
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
        >
            <>
                <Form onClose={onClose} />
            </>
        </Formik>
    )
}

export { FormCreateOrganization }