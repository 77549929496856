import {
    Modal,
    Box,
    Typography
} from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: "white",
    borderRadius: 1,
    border:"none",
    outline: "none"
};

const CustomModal = ({ open, children, modalStyle, bodyStyle, title }) => {
    return (

        <Modal
            open={open}
        >
            <Box sx={style} style={{ ...modalStyle }}>

                {
                    title && (<Box style={{ backgroundColor: "#FFE7D9" }} p={1} textAlign={"center"}>
                        <Typography variant="h6" gutterBottom>
                            {title}
                        </Typography>
                    </Box>)
                }

                <Box p={2} style={{ ...bodyStyle }} >
                    {children}
                </Box>
            </Box>
        </Modal>


    )
}

export default CustomModal;